<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
      { 'gray-bg': $route.meta.hasGrayBg }
    ]"
  >
    <AlertNotification
      v-if="isShowNotification"
      class="alert-mobile"
      :notifications="notifications"
      @delete="deleteNotification"
    />
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot
        v-if="$device.mobile"
        name="breadcrumb"
      >
        <app-breadcrumb class="mb-2" />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import AlertNotification from '@/components/AlertNotification.vue'

import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    AlertNotification,
    AppBreadcrumb,
  },
  mixins: [envMixin],
  computed: {
    ...mapGetters([
      'notifications',
      'status',
    ]),
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth,
    }
  },
  methods: {
    ...mapActions([
      'readNotifications',
    ]),
    async deleteNotification(id) {
      await this.readNotifications({ id })
      if (this.status) {
        // eslint-disable-next-line no-underscore-dangle
        const notificationIndex = this.notifications.map(item => item._id).indexOf(id)
        if (notificationIndex > -1) {
          this.notifications.splice(notificationIndex, 1)
        }
      }
    },
  },
}
</script>

<style scoped>
.alert-mobile {
  margin-bottom: 20px;

    @media (min-width: 767px) {
      display: none !important;
    }
}
</style>
