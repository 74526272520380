<template>
  <div>
    <p
      v-if="isPartnerViewMode === 'true'"
      class="cursor-pointer m-0 underline font-14 font-medium"
      @click="toggleShowPartnerView"
    >
      {{ $t('partnerView.textSwitchToSeller') }}
    </p>
    <p
      v-else
      class="cursor-pointer m-0 underline font-14 font-medium"
      @click="toggleShowPartnerView"
    >
      {{ $t('partnerView.textSwitchToPartner') }}
    </p>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import partnerMixin from '@/mixins/partnerMixin'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  components: {
  },

  mixins: [partnerMixin],
  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    async toggleShowPartnerView() {
      const partnerView = localStorage.getItem('partnerView')
      if (partnerView === 'true') {
        await localStorage.setItem('partnerView', 'false')
        window.location.href = '/'
      } else {
        await localStorage.setItem('partnerView', 'true')
        window.location.href = '/partner-management'
      }
    },
  },
}
</script>
