import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-my-ads-account',
    title: i18n.t('account.textPageTitle'),
    icon: 'UsersIcon',
    route: 'my-ads-account',
    tooltip: 'My ad accounts',
    // children: [
    //   {
    //     title: i18n.t('account.textAdsNavTitle'),
    //     route: 'ads-account',
    //   },
    //   {
    //     title: i18n.t('account.textRequestAdsPageTitle'),
    //     route: 'request-new-account',
    //   },
    // ],
  },
]
