<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-help"
  >
    <template #button-content>
      <feather-icon
        v-b-tooltip.hover.top.v-light="$t('home.textHelp')"
        icon="HelpCircleIcon"
        size="21"
      />
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://docs.ecomdymedia.com/"
      target="_blank"
    >
      <span>{{ $t('home.help.textHelpCenter') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="openContact"
    >
      <span>{{ $t('home.help.textContactSupport') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      to="/pricing"
      :target="isTikTokAppCenter ? '_blank' : '_self'"
    >
      <span>{{ $t('home.help.textPlansAndPricing') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://ecomdymedia.com/terms-policy"
      target="_blank"
    >
      <span>{{ $t('home.help.textTermAndPolicy') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://ecomdymedia.com"
      target="_blank"
    >
      <span>{{ $t('home.help.textAboutUs') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
import {
  VBTooltip, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [envMixin],
  methods: {
    openContact() {
      if (this.isExclusivePartner) {
        this.goToMessengerUrl()
      } else if (window?.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },
  },
}
</script>
<style lang="scss">
.dropdown-help .dropdown-menu {
  width: max-content;
}
</style>
