import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-product-link',
    title: i18n.t('layout.productLinks'),
    route: 'product-link',
    icon: 'LinkIcon',
    tooltip: 'Product Links',
  },
]
