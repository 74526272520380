import referralProgramNew from '@/navigation/vertical/referralProgramNew'
import dashboard from './dashboard'
import account from './account'
import payment from './payment'
import billing from './billing'
import productLinks from './productLinks'
// import referralOld from './referraOld'
// import referralProgram from './referralProgram'
import partner from './partner'
// import platform from './platform'
// import pixel from './pixel'

export default [
  ...dashboard,
  ...account,
  ...payment,
  // ...referralOld,
  ...productLinks,
  ...billing,
  // ...pixel,
  // ...referralProgram,
  ...partner,
  ...referralProgramNew,
  // ...platform,
]
