import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-referral-programs-new',
    title: i18n.t('layout.textAffiliateProgram'),
    route: 'affiliate-program',
    icon: 'Share2Icon',
    tooltip: i18n.t('layout.textAffiliateProgram'),
    tag: i18n.t('layout.new'),
  },
]
