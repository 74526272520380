import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-billing',
    title: i18n.t('layout.textBilling'),
    route: 'billing',
    icon: 'FileTextIcon',
    tooltip: 'Billing',
  },
]
