import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-overview',
    title: i18n.t('layout.textOverview'),
    route: 'dashboard',
    icon: 'HomeIcon',
    tooltip: i18n.t('layout.textOverview'),
  },
]
