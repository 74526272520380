<template>
  <btn-loading
    id="trigger-current-plan"
    data-testid="btn-current-plan"
    variant-convert="outline-warning"
    pill
    size="sm"
    class="font-medium d-flex align-items-center justify-content-center font-small-2"
    style="margin-right: 13px"
    to="/billing"
  >
    <img
      v-if="noPlan && !needToBeReviewed"
      alt="tick"
      :src="require('@/assets/images/pages/billing/crown-active.svg')"
      class="img-crown"
    >
    {{ planName }}
  </btn-loading>
</template>

<script>
/* eslint-disable camelcase */
import billingMixin from '@/mixins/billingMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import pricingMixin from '@/mixins/pricingMixin'

export default {
  components: {
    BtnLoading,
  },
  mixins: [billingMixin, pricingMixin],

  computed: {
    planName() {
      if (this.noPlan && !this.needToBeReviewed) {
        return 'Upgrade'
      } if (this.isNotEnterprise && this.isWhiteAgency) {
        return 'Enterprise'
      }
      return this.getPlanName
    },
  },
}
</script>
<style lang="scss" scoped>
  .img-crown{
    width: 17px;
    margin-right: 6px;
  }
</style>
