<script>

export default {
  name: 'AlertNotification',
  components: { },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
    }
  },
  computed: {
    disabledPrev() {
      return this.current <= 0
    },
    disabledNext() {
      return this.currentNotify >= this.notificationCount
    },
    currentNotify() {
      return this.notificationCount > 0 ? this.current + 1 : 0
    },
    notificationCount() {
      return this.notifications.length
    },
  },
  methods: {
    next() {
      if (this.disabledNext) return

      // eslint-disable-next-line no-plusplus
      this.current++
    },
    prev() {
      if (this.disabledPrev) return

      // eslint-disable-next-line no-plusplus
      this.current--
    },
    read(id) {
      this.$emit('delete', id)
      this.current = 0
    },
  },
}
</script>

<template>
  <div
    v-if="notificationCount"
    class="alert-notification d-flex align-items-center font-14"
  >
    <div class="control-action d-flex align-items-center text-primary">
      <button
        class="bg-transparent border-0"
        :class="{ 'text-black-50 cursor-not-allowed': disabledPrev}"
        :disabled="disabledPrev"
        @click="prev"
      >
        <b-icon-chevron-left />
      </button>
      <span>{{ currentNotify }} / {{ notificationCount }}</span>
      <button
        class="bg-transparent border-0"
        :class="{ 'text-black-50 cursor-not-allowed': disabledNext}"
        :disabled="disabledNext"
        @click="next"
      >
        <b-icon-chevron-right />
      </button>
    </div>
    <div
      v-for="(notification, index) in notifications"
      v-show="current === index"
      :key="notification._id"
      class="flex-fill"
    >
      <div
        class="d-flex align-items-center flex-fill"
      >
        <div
          class="flex-fill"
        >
          <span class="notification-content">{{ notification.content }}</span>
        </div>
        <button
          class="bg-transparent border-0"
          @click="read(notification._id)"
        >
          <b-icon-x />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.alert-notification {
  border-radius: 8px 8px 0 0;
  padding: 4px 16px;
  min-height: 30px;
  background: rgba(251, 176, 59, 0.1) ;

  @media (max-width: 767px) {
    padding: 4px 8px;
    min-height: 70px;
  }

  .control-action {
    min-width: 82px;
  }

  .notification-content {
    font-size: 0.8rem;

    @media (max-width: 767px) {
      font-size: 0.625rem;
    }
  }
}
</style>
