import i18n from '@/libs/i18n'

export default [
  {
    title: i18n.t('referralProgram.dashboardPageTitle'),
    route: 'partner-management',
    icon: 'AirplayIcon',
  },
  {
    title: i18n.t('referralProgram.accountManagementPageTitle'),
    route: 'partner-management-member',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('referralProgram.memberProductLinksTitle'),
    route: 'partner-management-product-link',
    icon: 'LinkIcon',
  },
  {
    title: i18n.t('partner.textPageMemberContactInfoTitle'),
    route: 'partner-management-contact-info',
    icon: 'ShieldIcon',
  },
  {
    title: i18n.t('referralProgram.giftCodeManagementTitle'),
    route: 'partner-management-giftcode',
    icon: 'GiftIcon',
  },
  {
    title: i18n.t('partner.partnerInfoURLTitle'),
    route: 'partner-info-url',
    icon: 'Link2Icon',
  },
]
