<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    v-b-tooltip.hover.right.v-light="hasStepGuide && !$device.mobile && !$device.ipad ? item.tooltip : ''"
    class="nav-item nav-item-mf"
    :class="{
      'active': isActive,
      'disabled': item.disabled || loading
    }"
  >
    <b-link
      :id="`tab-${formatTitleTab(item.title)}-navbar`"
      :data-testid="`tab-${formatTitleTab(item.title)}-navbar`"
      v-bind="linkProps"
      class="d-flex align-items-center justify-content-flex-start nav-a"
      @click="item.icon === 'HomeIcon' ? handleClick : ''"
    >
      <feather-icon
        v-if="item.icon"
        :icon="item.icon"
      />
      <span
        class="menu-title text-truncate"
        :class="item.icon ? 'pl-0' : 'left-title'"
      >{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="tag-new-program"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, VBTooltip } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { createNamespacedHelpers } from 'vuex'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BLink,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinVerticalNavMenuLink, stepGuideMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive,
      linkProps,
      updateIsActive,
    } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

  computed: {
    ...mapGetters(['loading']),

    formatTitleTab() {
      return title => title.toLowerCase().replaceAll(' ', '-')
    },
  },

  methods: {
    handleClick() {
      if (this.hasStepGuide) {
        window.location.href = '/'
      } else {
        this.$router.push('/')
      }
    },
  },

}
</script>

<style lang="scss" scoped>

.nav-item-mf {
  margin: 0.6rem 0;
}

.nav-a {
  background: #F8F8F8;
  border-radius: 5px;
}

.ml-menu {
  margin-left: 2.1rem;
}

.left-title {
  padding-left: 28px;
}

.tag-new-program {
    position: absolute;
    right: 0;
    top: -10px;
    background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
}
</style>
