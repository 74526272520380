import i18n from '@/libs/i18n'

export default [
  {
    id: 'nav-payment',
    title: i18n.t('layout.textPayment'),
    route: 'payment',
    icon: 'CreditCardIcon',
    tooltip: 'Payment',
  },
]
